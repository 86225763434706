<template>
    <div class="ideas-widget-top-five">
        <div class="ideas-widget-top-five__header">
            <div class="ideas-widget-top-five__title">
                Топ-5 сотрудников по количеству идей
            </div>

            <div class="ideas-widget-top-five__tabs">
                <div
                    v-for="(tab, index) in tabsList"
                    :key="`ideas-widget-top-${index}`"
                    :class="{'active': tab.code === tabActive}"
                    @click="tabActive = tab.code"
                    class="ideas-widget-top-five__tabs-item"
                >
                    {{ tab.name }}
                </div>
            </div>
        </div>

        <div
            v-if="topUsers && topUsers.length > 0"
            class="ideas-widget-top-five__list"
        >
            <div
                v-for="(user, index) in topUsers"
                :key="`ideas-widget-${index}`"
                class="ideas-widget-top-five__list-item"
            >
                <div class="ideas-widget-top-five__avatar">
                    <img :src="user.image_zoom_out || defaultAvatar" width="40" height="40">
                </div>

                <div class="ideas-widget-top-five__name">
                    <router-link :to="`/auth/profile/${user.id}/`">
                        {{ user.full_name }}
                    </router-link>
                </div>

                <div class="ideas-widget-top-five__count">
                    {{user.count}}
                </div>
            </div>
        </div>
        <div class="ideas-widget-top-five__empty" v-if="topUsers && topUsers.length === 0">
            <div class="ideas-widget-top-five__empty-image"></div>
            <div class="ideas-widget-top-five__empty-text">
                Недостаточно идей для формирования статистики
            </div>
        </div>
    </div>
</template>

<script>
    import defaultAvatar from 'assets/img/avatars/default_avatar.jpg'

    export default {
        name: 'IdeasWidgetActivity',
        props: {
            activity: {
                default: [],
                required: true
            }
        },
        computed: {
            topUsers: function () {
                if (this.activity === []) {
                    return []
                }
                if (this.tabActive === 'month') {
                    return this.activity.month
                } else if (this.tabActive === 'half_year') {
                    return this.activity.half_year
                } else if (this.tabActive === 'year') {
                    return this.activity.year
                }
            }
        },
        data () {
            return {
                defaultAvatar,
                tabActive: 'month',
                tabsList: [
                    {
                        name: 'за месяц',
                        code: 'month'
                    },
                    {
                        name: 'полгода',
                        code: 'half_year'
                    },
                    {
                        name: 'всё время',
                        code: 'year'
                    }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "#sass/v-style";

.ideas-widget-top-five {
    width: 100%;
    padding: 40px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.12);
    &__title {
        width: 100%;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        color: black;
        margin-bottom: 32px;
    }

    &__tabs {
        width: 100%;
        border-bottom: 1px solid #C5CDD9;
        display: flex;
        justify-content: flex-start;
    }

    &__tabs-item {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 23px;
        flex-basis: 67px;
        min-height: 39px;
        margin-right: 32px;
        margin-bottom: -1px;
        text-align: center;
        white-space: nowrap !important;
        &:hover {
            cursor: pointer;
        }
    }

    &__list {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-basis: 41px;
        margin-top: 16px;
    }

    &__avatar {
        flex-basis: 41px;
        min-width: 41px;
        height: 41px;
        margin-right: 16px;
        border-radius: 40px;
        overflow: hidden;
    }

    &__name {
        font-style: normal;
        font-weight: normal;
        font-size: rem(14);
        line-height: 24px;
        color: #2173D3;
        flex-basis: 300px;
    }

    &__count {
        font-style: normal;
        font-weight: normal;
        font-size: rem(14);
        line-height: 24px;
        flex-basis: 80px;
        text-align: right;
        white-space: nowrap;
    }

    &__empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 16px;
    }

    &__empty-image {
        padding-left: 25%;
        padding-right: 25%;
    }

    &__empty-text {
        text-align: center;
        padding-left: 25%;
        padding-right: 25%;
    }

    @media (min-width: 1026px) and (max-width: 1406px){
        .ideas-widget-top-five {
            &__tabs-item {
                width: 33%;
                margin-right: 0 !important;
            }
        }
    }

    @media (max-width: 1026px) {
        .ideas-widget-top-five {
            width: 100%;
        }
    }
}

.active {
    border-bottom: 3px solid #FBC04F;
}
</style>
